import styled from 'styled-components';

const ContactStyled = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #f7f7f5;

    .top-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 150px;
        padding: 0 90px;
        box-sizing: border-box;

        h1 {
            width: 312px;
            margin: 80px 80px 0 51px;

            @media screen and (max-width: 1024px) {
                margin-left: 110px;
            }

            @media screen and (max-width: 768px) {
                margin-left: 34px;
            }
        }

        img {
            width: 50%;
            max-width: 725px;
            min-width: 550px;
            height: auto;

            @media screen and (max-width: 1024px) {
                width: 100%;
                max-width: 100%;
            }
        }

        @media screen and (max-width: 1024px) {
            flex-direction: column-reverse;
            margin-top: 81px;
            padding: 0;
        }
    }

    .contact-info__container {
        display: flex;
        justify-content: space-between;
        padding: 0 90px;

        .contact-form__comp {
            margin-top: -267px;

            @media screen and (max-width: 1024px) {
                margin: 40px 0;
                padding: 0;
            }
        }

        aside {
            width: 330px;
            margin-top: 57px;
            margin-left: 52px;

            .info-container {
                margin: 42px 0 28px;

                .phone {
                    color: #4a4a4a;
                    text-decoration: none;
                    border-bottom: none;
                }
            }

            h4 {
                font-family: sofia-pro, sans-serif;
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0.19px;
                margin: 0 0 31px 0;
                color: #404041;
            }

            p {
                margin: 0 0 15px 0;
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 28px;
                color: #4a4a4a;
            }

            a {
                text-decoration: none;
                color: #266fc5;
                border-bottom: 2px solid #266fc5;

                &:hover {
                    border-bottom: none;
                }

                &:focus {
                    color: #be3a34;
                    border-bottom: 2px solid #be3a34;
                }
            }

            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }

        @media screen and (max-width: 768px) {
            padding: 0 14px;
        }
    }
`;

export default ContactStyled;
