import React from 'react';
import { withSize } from 'react-sizeme';
import PropTypes from 'prop-types';

import ContactStyled from '../components/page-styles/contact-styles';

import ContactForm from '../components/ContactForm/ContactForm';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import SEO from '../components/SEO';

import ContactImageUpperCorner from '../images/contact-page-upper-corner.png';

const ContactPage = ({ path, size }) => (
    <Layout path={path} isContactPage={true}>
        <ContactStyled className="contact">
            <SEO title="Contact Us" />
            <section className="top-row">
                <Heading text="Contact Us" headingType="h1" color="#323333" />
                <img
                    src={ContactImageUpperCorner}
                    alt="Red door of semi-truck with Saratoga Logo"
                    width="725"
                    height="483"
                />
            </section>
            <section className="contact-info__container">
                <ContactForm isContactPage={true} />
                <aside>
                    <p>
                        Exceptional customer service is our number one goal. Our
                        Staff is here to respond to any issue, big or small.
                    </p>
                    <div className="info-container">
                        <h4>24/7 Support</h4>
                        <a className="phone" href="tel:865-474-2562">
                            (865) 474-2562
                        </a>
                    </div>

                    <div className="info-container">
                        <h4>General Questions</h4>
                        <p>
                            For general questions or to become a new customer
                            contact:
                        </p>
                        <a href="mailto:info@saratogarm.com">
                            info@saratogarm.com
                        </a>
                    </div>

                    <div className="info-container">
                        <h4>Corporate Headquarters</h4>
                        <p>
                            Saratoga Solutions
                            <br />
                            20 Greenway Plaza, Suite 200
                            <br />
                            Houston, TX 77046
                        </p>
                        <a className="phone" href="tel:865-474-2562">
                            (865) 474-2562
                        </a>
                    </div>
                </aside>
            </section>
        </ContactStyled>
    </Layout>
);

ContactPage.propTypes = {
    size: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export default withSize()(ContactPage);
